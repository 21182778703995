<template>
  <div class="historiaVisita mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Historial de visitas por cliente</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :loading="loading_t"
                  :headers="headers"
                  :items="lista"
                  :search="search"
                  hide-default-footer
                  :items-per-page="perPage"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:top>
                    <v-row no-gutters>
                      <v-col cols="10" md="12" sm="12">
                        <v-row no-gutters>
                          <v-col cols="11" md="5" sm="7">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              label="Buscar"
                              v-model="search"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="4">
                            <div style="display: flex">
                              <v-subheader> Clientes X pag </v-subheader>
                              <div style="width: 120px">
                                <v-select
                                  :items="itemsPerPage"
                                  v-model="perPage"
                                  outlined
                                  dense
                                >
                                </v-select>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" md="3" sm="4">
                            <v-btn color="black" dark @click="download()">
                              <v-icon left>mdi-download</v-icon>
                              Descargar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-pagination
                          dense
                          v-model="page"
                          :length="pageCount"
                          :total-visible="6"
                        ></v-pagination>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>Total clientes: {{ clientes.length }}</h3>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.saldo="{ item }">
                    {{ item.saldo | currency }}
                  </template>
                  <template v-slot:item.ultima="{ item }">
                    <span v-if="!item.ultima" style="color: #fb8c00">
                      Sin visitas agendadas
                    </span>
                    <span v-else>
                      {{ moment(item.ultima).format("ll") }}
                    </span>
                  </template>
                  <template v-slot:item.proxima="{ item }">
                    <span v-if="!item.proxima" style="color: #fb8c00">
                      Sin visitas agendadas
                    </span>
                    <span v-else>
                      {{ moment(item.proxima).format("ll") }}
                    </span>
                  </template>
                  <template v-slot:item.dias="{ item }">
                    <span v-if="item.dias > 29" style="color: red">
                      {{ item.dias }}
                    </span>
                    <span v-else style="color: green">{{ item.dias }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    pageCount: 0,
    loading_t: false,
    perPage: 25,
    page: 1,
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    search: "",
    clientes: [],
    headers: [
      {
        text: "ID",
        value: "cedula",
        align: "start",
      },
      {
        text: "Cliente",
        value: "nombre",
      },
      {
        text: "Telefono",
        value: "telefono",
        sortable: false,
      },
      {
        text: "Dirección",
        value: "direccion",
        sortable: false,
      },
      {
        text: "Saldo",
        value: "saldo",
        sortable: false,
      },
      {
        text: "Ultima Visita",
        value: "ultima",
        sortable: false,
      },
      {
        text: "Proxima visita",
        value: "proxima",
        sortable: false,
      },
      {
        text: "Dias sin visita",
        value: "dias",
        sortable: false,
      },
    ],
  }),
  methods: {
    loadClientesSaldo() {
      this.clientes = [];
      this.loading_t = true;
      const body = {
        route: `/historialVisitasPorClienteConSaldo`,
        params: {
          date: moment().format("YYYY-MM-DD"),
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    download() {
      const header = {
        Columna_A: "ID",
        Columna_B: "Cliente",
        Columna_C: "Telefono",
        Columna_D: "Direccion",
        Columna_E: "Saldo",
        Columna_F: "Ultima Visita",
        Columna_G: "Proxima Visita",
        Columna_H: "Dias Sin Visita",
      };
      let data = this.clientes;

      data = data
        .map((cliente) => {
          const actual = moment().format("YYYY-MM-DD");

          let dias = 0;

          if (cliente.ultima != null && cliente.ultima != 0) {
            if (cliente.proxima != null && cliente.proxima != 0) {
              dias = moment(cliente.proxima).diff(
                moment(cliente.ultima),
                "days"
              );
            } else {
              dias = moment(actual).diff(moment(cliente.ultima), "days");
            }
          }
          return {
            Columna_A: cliente.cedula,
            Columna_B: cliente.nombre,
            Columna_C: cliente.telefono,
            Columna_D: cliente.direccion,
            Columna_E: cliente.saldo,
            Columna_F: cliente.ultima,
            Columna_G: cliente.proxima,
            Columna_H: dias,
          };
        })
        .sort((a, b) => {
          return b.Columna_E - a.Columna_E;
        });

      data.unshift(header);
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = "Visitas Programadas";
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadClientesSaldo();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      let clientes = this.clientes.map((cliente) => {
        const actual = moment().format("YYYY-MM-DD");

        let dias = 0;

        if (cliente.ultima != null && cliente.ultima != 0) {
          if (cliente.proxima != null && cliente.proxima != 0) {
            dias = moment(cliente.proxima).diff(moment(cliente.ultima), "days");
          } else {
            dias = moment(actual).diff(moment(cliente.ultima), "days");
          }
        }

        return Object.assign({ dias }, cliente);
      });

      return clientes.sort((a, b) => {
        return b.saldo - a.saldo;
      });
    },
  },
};
</script>
